import { FC } from 'react';
import { observer } from 'mobx-react';
import {
    Box,
    Button,
    Checkbox,
    Flex,
    Header,
    Modal,
    Row,
    styled,
    Subheader,
} from '@streem/ui-react';
import { useListGroupsStore } from '../../hooks/list_store_hooks';
import { nonDisplayableGroupNames } from '../../util/constants';
import { useCompanyCallLogRequest } from '../../hooks/use_company_call_log_request';
import { useGlobalStore } from '../../hooks/use_global_context';
import { CheckboxLabel, CheckboxWrap } from '../../forms/terms_and_conditions_form';
import { recordElementClicked } from '@streem/analytics';

interface CallFiltersModalProps {
    isOpen: boolean;
    onClose: () => void;
    pathName: string;
}

export const CallFiltersModal: FC<CallFiltersModalProps> = observer(
    ({ isOpen, onClose, pathName }) => {
        const groupsStore = useListGroupsStore();
        const { uiStore } = useGlobalStore();
        const selectedGroupFilters = uiStore.appliedGroupFilters;
        const { fetchCallLog, numberOfResults } = useCompanyCallLogRequest();

        const handleFilterRequest = (groupName: string) => {
            uiStore.addOrRemoveSelectedGroupFilter(groupName, pathName);
            fetchCallLog(selectedGroupFilters);
        };

        const filteredGroups = groupsStore.results.filter(group => {
            return !nonDisplayableGroupNames.includes(group.name.toUpperCase());
        });

        const handleResetFilters = () => {
            uiStore.clearSelectedGroupFilters(pathName);
            fetchCallLog(selectedGroupFilters);
        };

        const handleShowResultsClose = () => {
            recordElementClicked(`${pathName}-log-filter-show-results-clicked`);
            onClose();
        };

        const handleXButtonClose = () => {
            recordElementClicked(`${pathName}-log-filter-x-clicked`);
            onClose();
        };

        // TODO NOTE: move checkbox container to a seperated component so it can be used later by AHS virtual expert
        return (
            <Modal
                isOpen={isOpen}
                hideCloseButton={false}
                data-testid="call-filters-modal"
                onClose={handleXButtonClose}
            >
                <Flex mb={24} justifyContent="flex-start" alignItems="center">
                    <Header data-testid="call-filters-header" size="xxlarge">
                        Filter Streem Calls
                    </Header>
                </Flex>
                <Row>
                    <Subheader data-testid="call-filters-trade-header" size="large" semibold>
                        Trade Groups
                    </Subheader>
                </Row>
                <Box mb={4}>
                    <CheckboxContainer>
                        {filteredGroups.map(group => (
                            <CheckboxWrap key={group.name}>
                                <Checkbox
                                    data-testid={`call-filters-checkbox-${group.name}`}
                                    key={group.name}
                                    checked={selectedGroupFilters?.some(
                                        filter =>
                                            filter.toLocaleLowerCase() ===
                                            group.name.toLocaleLowerCase(),
                                    )}
                                    label={group.name}
                                    onChange={() => handleFilterRequest(group.name)}
                                />
                                <CheckboxLabel
                                    data-testid={`call-filters-checkbox-label-${group.name}`}
                                >
                                    {group.name}
                                </CheckboxLabel>
                            </CheckboxWrap>
                        ))}
                    </CheckboxContainer>
                </Box>
                <Flex mt="2rem" justifyContent="space-between">
                    <Button
                        variant="secondary"
                        data-testid="call-filters-button-reset"
                        onClick={handleResetFilters}
                    >
                        Reset
                    </Button>
                    <Button
                        ml={4}
                        mobile
                        onClick={handleShowResultsClose}
                        data-testid="call-filters-button-results"
                    >
                        {`Show ${numberOfResults} results`}
                    </Button>
                </Flex>
            </Modal>
        );
    },
);

const CheckboxContainer = styled('div')({
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
    columnGap: '5px',
    width: '45vw',
});
