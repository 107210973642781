/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiCallSource
 */
export interface StreemApiCallSource {
    /**
     * 
     * @type {string}
     * @memberof StreemApiCallSource
     */
    sid?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiCallSource
     */
    companyId?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiCallSource
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiCallSource
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof StreemApiCallSource
     */
    isDefault?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StreemApiCallSource
     */
    logoUrl?: string;
    /**
     * 
     * @type {number}
     * @memberof StreemApiCallSource
     */
    maxDiagnoses?: number;
}

export function StreemApiCallSourceFromJSON(json: any): StreemApiCallSource {
    return StreemApiCallSourceFromJSONTyped(json, false);
}

export function StreemApiCallSourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiCallSource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sid': !exists(json, 'sid') ? undefined : json['sid'],
        'companyId': !exists(json, 'company_id') ? undefined : json['company_id'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'isDefault': !exists(json, 'is_default') ? undefined : json['is_default'],
        'logoUrl': !exists(json, 'logo_url') ? undefined : json['logo_url'],
        'maxDiagnoses': !exists(json, 'max_diagnoses') ? undefined : json['max_diagnoses'],
    };
}

export function StreemApiCallSourceToJSON(value?: StreemApiCallSource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sid': value.sid,
        'company_id': value.companyId,
        'code': value.code,
        'name': value.name,
        'is_default': value.isDefault,
        'logo_url': value.logoUrl,
        'max_diagnoses': value.maxDiagnoses,
    };
}


